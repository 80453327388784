import Vue from 'vue'
import Router from 'vue-router'

// 解决点击当前路由报错
const routerPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => {})
};

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '*', redirect: '/login' },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/login.vue'),
    },
    {
      path: '/',
      redirect: '/ticket/list'
    },
    {
      path: '',
      component: () => import('@/views/home.vue'),
      children: [
        {
          path: '/ticket',
          name: 'ticket',
          component: { render: (e) => e('router-view') },
          redirect: '/ticket/list',
          children: [
            {
              path: 'list',
              name: 'ticketList',
              component: () => import('@/pages/ticket/list.vue'),
              meta: {
                role: [0, 1, 2, 3, 4, 5, 6, 7]  // 0第三方客服、1erp用户、2管理员、3中国区客服专员、4中国区客服主管，5美国区客服专员，6美国区客服主管,7第三方客服主管
              }
            },
            {
              path: 'difficultTickets',
              name: 'difficultTickets',
              component: () => import('@/pages/ticket/DifficultTickets.vue'),
              meta: {
                role: [0, 1, 2, 3, 4, 5, 6, 7]
              }
            },
            {
              path: 'detail/:id',
              name: 'ticketDetail',
              component: () => import('@/pages/ticket/detail.vue'),
              meta: {
                role: [0, 1, 2, 3, 4, 5, 6, 7]
              }
            },
            {
              path: 'BNReplace',
              name: 'BNReplace',
              component: () => import('@/pages/ticket/BNReplace.vue'),
              meta: {
                role: [2, 4, 6]
              }
            },
            {
              path: 'RemarkList',
              name: 'RemarkList',
              component: () => import('@/pages/ticket/RemarkList.vue'),
              meta: {
                role: [2, 3, 4, 6]
              }
            }
          ]
        },
        {
          path: '/ship',
          name: 'ship',
          component: () => import('@/pages/ship/index.vue'),
          meta: {
            role: [1, 2]
          }
        },
        {
          path: '/password',
          name: 'password',
          component: () => import('@/pages/password/Index.vue'),
          meta: {
            role: [0, 1, 2, 3, 4, 5, 6, 7]
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/pages/account/Index.vue'),
          meta: {
            role: [2]
          }
        },
        {
          path: '/CPSC',
          name: 'CPSC',
          component: () => import('@/pages/CPSC/Index.vue'),
          meta: {
            role: [2, 4, 6]
          }
        },
        {
          path: '/sedgwick-account',
          name: 'sedgwickAccount',
          component: () => import('@/pages/account/Sedgwick.vue'),
          meta: {
            role: [2, 7]
          }
        },
        {
          path: '/stock',
          name: 'stockBaseTableManagement',
          component: () => import('@/pages/stock/Index.vue'),
          meta: {
            role: [1, 2]
          }
        },
        {
          path: '/stock/add',
          name: 'stockBaseTableManagementAdd',
          component: () => import('@/pages/stock/Add.vue'),
          meta: {
            role: [1, 2]
          }
        },
        {
          path: '/stock/edit/:id',
          name: 'stockBaseTableManagementEdit',
          component: () => import('@/pages/stock/Edit.vue'),
          meta: {
            role: [1, 2]
          }
        },
        {
          path: '/report',
          name: 'report',
          component: () => import('@/pages/reports/Index.vue'),
          meta: {
            role: [2, 4]
          }
        }
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (to.name === 'Login') {
    next()
  } else if (!userInfo) {
    next('/login')
  } else if (to.meta.role.includes(userInfo.role)) {
    next()
  } else {
    const path = userInfo.role === 1 ? '/ship' : '/ticket/list'
    next(path)
  }
  // next()
})

export default router
