import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '../router'

const myAxios = axios.create({
  timeout: 60000,
  withCredentials: false, // 表示跨域请求时是否需要使用凭证(cookie/session)，默认false
  baseURL: PAGECONF.hostApi,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json', // 返回数据类型
})

myAxios.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('token') || undefined
  return config
})

const codeList = [430012, 410005, 430015, 410004, 410003, 430008, 430021]
myAxios.interceptors.response.use(function (response) {
  const result = response.data
  if (result.code === 410002){
    Promise.reject(result.errorMsg)
    message.error(result.errorMsg)
    router.push('/login')
  } else if (codeList.includes(result.code)) {  // 单独处理的状态码
    return Promise.reject(result)
  }
  else if (result.code !== 0) {
    message.error(result.errorMsg)
    return Promise.reject(result)
  } else {
    return result
  }
}, function (error) {
  message.error('A System Error Occured. Please Contact the Administrator.')
  return Promise.reject(error)
})

export default myAxios
